*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

body {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, "Liberation Mono", Menlo,
    monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

summary {
  display: list-item;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}

/* button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
} */

fieldset {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
}

body {
  font-family: inherit;
  line-height: inherit;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e4e4e7;
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #a1a1aa;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #a1a1aa;
}

button,
[role="button"] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

pre,
code,
kbd,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  /* vertical-align: middle; */
}

img,
video {
  max-width: 100%;
  height: auto;
}

/* [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"], */
/* [multiple], */
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #71717a;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

/* [type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus 
{
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/
/*!*/
/* --tw-ring-offset-width: 0px; */
/* --tw-ring-offset-color: #fff; */
/* --tw-ring-color: #2563eb; */
/* --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  border-color: #2563eb; */
/* } */
*/ input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #71717a;
  opacity: 1;
}

input::placeholder,
textarea::placeholder {
  color: #71717a;
  opacity: 1;
}

/* select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%2371717a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
} */

/* [multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
  color-adjust: unset;
} */

[type="checkbox"],
[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #3c97e8;
  background-color: #fff;
  border-color: #71717a;
  border-width: 1px;
}

[type="checkbox"] {
  border-radius: 0px;
}

[type="radio"] {
  border-radius: 100%;
}

/* [type="checkbox"]:focus, */
[type="radio"]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border-color: transparent;
  background-color: #3c97e8;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover,
[type="checkbox"]:checked:focus,
[type="radio"]:checked:hover,
[type="radio"]:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type="checkbox"]:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type="checkbox"]:indeterminate:hover,
[type="checkbox"]:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}

/* [type="file"] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}

[type="file"]:focus {
  outline: 1px auto -webkit-focus-ring-color;
} */

.tw-container {
  width: 100%;
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

.tw-container {
  width: 100%;
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1280px;
  }
}

.tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1;
}

.tw-space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

.tw-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.tw-divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.tw-divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 1;
}

.tw-divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 1;
}

.tw-divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.tw-divide-dashed > :not([hidden]) ~ :not([hidden]) {
  border-style: dashed;
}

.tw-divide-dotted > :not([hidden]) ~ :not([hidden]) {
  border-style: dotted;
}

.tw-divide-double > :not([hidden]) ~ :not([hidden]) {
  border-style: double;
}

.tw-divide-none > :not([hidden]) ~ :not([hidden]) {
  border-style: none;
}

.tw-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.tw-not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.tw-appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.tw-bg-fixed {
  background-attachment: fixed;
}

.tw-bg-local {
  background-attachment: local;
}

.tw-bg-scroll {
  background-attachment: scroll;
}

.tw-bg-clip-border {
  background-clip: border-box;
}

.tw-bg-clip-padding {
  background-clip: padding-box;
}

.tw-bg-clip-content {
  background-clip: content-box;
}

.tw-bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.tw-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.tw-bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.tw-bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 213, 255, var(--tw-bg-opacity));
}

.tw-bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 85, 247, var(--tw-bg-opacity));
}

.tw-bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.tw-bg-lightBlue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 230, 253, var(--tw-bg-opacity));
}

.tw-bg-lightBlue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 189, 248, var(--tw-bg-opacity));
}

.tw-bg-lightBlue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 165, 233, var(--tw-bg-opacity));
}

.tw-bg-lightBlue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 132, 199, var(--tw-bg-opacity));
}

.tw-bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 246, 228, var(--tw-bg-opacity));
}

.tw-bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 184, 166, var(--tw-bg-opacity));
}

.tw-bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.tw-bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.tw-bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.tw-bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
}

.tw-bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 215, 170, var(--tw-bg-opacity));
}

.tw-bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
}

.tw-bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.tw-bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}

.tw-bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}

.tw-bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.tw-bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.tw-bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.tw-bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}

.tw-bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}

.tw-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}

.tw-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
}

.tw-bg-blueGray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}

.tw-bg-blueGray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}

.tw-bg-blueGray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.tw-bg-blueGray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}

.tw-bg-blueGray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
}

.tw-bg-blueGray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 41, 59, var(--tw-bg-opacity));
}

.tw-focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.tw-focus\:bg-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
}

.tw-active\:bg-indigo-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.tw-active\:bg-red-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.tw-active\:bg-blueGray-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}

.tw-active\:bg-blueGray-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}

.tw-bg-center {
  background-position: center;
}

.tw-bg-repeat {
  background-repeat: repeat;
}

.tw-bg-no-repeat {
  background-repeat: no-repeat;
}

.tw-bg-repeat-x {
  background-repeat: repeat-x;
}

.tw-bg-repeat-y {
  background-repeat: repeat-y;
}

.tw-bg-repeat-round {
  background-repeat: round;
}

.tw-bg-repeat-space {
  background-repeat: space;
}

.tw-bg-cover {
  background-size: cover;
}

.tw-bg-full {
  background-size: 100%;
}

.tw-border-collapse {
  border-collapse: collapse;
}

.tw-border-separate {
  border-collapse: separate;
}

.tw-border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}

.tw-border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(228, 228, 231, var(--tw-border-opacity));
}

.tw-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(212, 212, 216, var(--tw-border-opacity));
}

.tw-border-blueGray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 250, 252, var(--tw-border-opacity));
}

.tw-border-blueGray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(241, 245, 249, var(--tw-border-opacity));
}

.tw-border-blueGray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}

.tw-border-blueGray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(203, 213, 225, var(--tw-border-opacity));
}

.tw-border-blueGray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(100, 116, 139, var(--tw-border-opacity));
}

.tw-border-blueGray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(71, 85, 105, var(--tw-border-opacity));
}

.tw-focus\:border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.tw-focus\:border-indigo-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}

.tw-focus\:border-gray-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(212, 212, 216, var(--tw-border-opacity));
}

.tw-focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(113, 113, 122, var(--tw-border-opacity));
}

.tw-rounded {
  border-radius: 0.25rem;
}

.tw-rounded-md {
  border-radius: 0.375rem;
}

.tw-rounded-lg {
  border-radius: 0.5rem;
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.tw-rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.tw-rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.tw-rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.tw-rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.tw-rounded-tl {
  border-top-left-radius: 0.25rem;
}

.tw-rounded-tr {
  border-top-right-radius: 0.25rem;
}

.tw-rounded-br {
  border-bottom-right-radius: 0.25rem;
}

.tw-rounded-bl {
  border-bottom-left-radius: 0.25rem;
}

.tw-border-solid {
  border-style: solid;
}

.tw-border-dashed {
  border-style: dashed;
}

.tw-border-dotted {
  border-style: dotted;
}

.tw-border-double {
  border-style: double;
}

.tw-border-none {
  border-style: none;
}

.tw-border-0 {
  border-width: 0px;
}

.tw-border-2 {
  border-width: 2px;
}

.tw-border {
  border-width: 1px;
}

.tw-border-t-0 {
  border-top-width: 0px;
}

.tw-border-r-0 {
  border-right-width: 0px;
}

.tw-border-l-0 {
  border-left-width: 0px;
}

.tw-border-b-2 {
  border-bottom-width: 2px;
}

.tw-border-t {
  border-top-width: 1px;
}

.tw-border-r {
  border-right-width: 1px;
}

.tw-border-b {
  border-bottom-width: 1px;
}

.tw-border-l {
  border-left-width: 1px;
}

.tw-box-border {
  box-sizing: border-box;
}

.tw-box-content {
  box-sizing: content-box;
}

.tw-cursor-pointer {
  cursor: pointer;
}

.tw-block {
  display: block;
}

.tw-inline-block {
  display: inline-block;
}

.tw-inline {
  display: inline;
}

.tw-flex {
  display: flex;
}

.tw-inline-flex {
  display: inline-flex;
}

.tw-table {
  display: table;
}

.tw-table-caption {
  display: table-caption;
}

.tw-table-cell {
  display: table-cell;
}

.tw-table-column {
  display: table-column;
}

.tw-table-column-group {
  display: table-column-group;
}

.tw-table-footer-group {
  display: table-footer-group;
}

.tw-table-header-group {
  display: table-header-group;
}

.tw-table-row-group {
  display: table-row-group;
}

.tw-table-row {
  display: table-row;
}

.tw-flow-root {
  display: flow-root;
}

.tw-grid {
  display: grid;
}

.tw-inline-grid {
  display: inline-grid;
}

.tw-contents {
  display: contents;
}

.tw-hidden {
  display: none;
}

.tw-flex-row {
  flex-direction: row;
}

.tw-flex-row-reverse {
  flex-direction: row-reverse;
}

.tw-flex-col {
  flex-direction: column;
}

.tw-flex-col-reverse {
  flex-direction: column-reverse;
}

.tw-flex-wrap {
  flex-wrap: wrap;
}

.tw-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.tw-flex-nowrap {
  flex-wrap: nowrap;
}

.tw-place-items-auto {
  place-items: auto;
}

.tw-place-items-start {
  place-items: start;
}

.tw-place-items-end {
  place-items: end;
}

.tw-place-items-center {
  place-items: center;
}

.tw-place-items-stretch {
  place-items: stretch;
}

.tw-place-content-center {
  place-content: center;
}

.tw-place-content-start {
  place-content: start;
}

.tw-place-content-end {
  place-content: end;
}

.tw-place-content-between {
  place-content: space-between;
}

.tw-place-content-around {
  place-content: space-around;
}

.tw-place-content-evenly {
  place-content: space-evenly;
}

.tw-place-content-stretch {
  place-content: stretch;
}

.tw-place-self-auto {
  place-self: auto;
}

.tw-place-self-start {
  place-self: start;
}

.tw-place-self-end {
  place-self: end;
}

.tw-place-self-center {
  place-self: center;
}

.tw-place-self-stretch {
  place-self: stretch;
}

.tw-items-start {
  align-items: flex-start;
}

.tw-items-end {
  align-items: flex-end;
}

.tw-items-center {
  align-items: center;
}

.tw-items-baseline {
  align-items: baseline;
}

.tw-items-stretch {
  align-items: stretch;
}

.tw-content-center {
  align-content: center;
}

.tw-content-start {
  align-content: flex-start;
}

.tw-content-end {
  align-content: flex-end;
}

.tw-content-between {
  align-content: space-between;
}

.tw-content-around {
  align-content: space-around;
}

.tw-content-evenly {
  align-content: space-evenly;
}

.tw-self-auto {
  align-self: auto;
}

.tw-self-start {
  align-self: flex-start;
}

.tw-self-end {
  align-self: flex-end;
}

.tw-self-center {
  align-self: center;
}

.tw-self-stretch {
  align-self: stretch;
}

.tw-justify-items-auto {
  justify-items: auto;
}

.tw-justify-items-start {
  justify-items: start;
}

.tw-justify-items-end {
  justify-items: end;
}

.tw-justify-items-center {
  justify-items: center;
}

.tw-justify-items-stretch {
  justify-items: stretch;
}

.tw-justify-start {
  justify-content: flex-start;
}

.tw-justify-end {
  justify-content: flex-end;
}

.tw-justify-center {
  justify-content: center;
}

.tw-justify-between {
  justify-content: space-between;
}

.tw-justify-around {
  justify-content: space-around;
}

.tw-justify-evenly {
  justify-content: space-evenly;
}

.tw-justify-self-auto {
  justify-self: auto;
}

.tw-justify-self-start {
  justify-self: start;
}

.tw-justify-self-end {
  justify-self: end;
}

.tw-justify-self-center {
  justify-self: center;
}

.tw-justify-self-stretch {
  justify-self: stretch;
}

.tw-flex-1 {
  flex: 1 1 0%;
}

.tw-flex-auto {
  flex: 1 1 auto;
}

.tw-flex-initial {
  flex: 0 1 auto;
}

.tw-flex-grow {
  flex-grow: 1;
}

.tw-flex-shrink {
  flex-shrink: 1;
}

.tw-float-right {
  float: right;
}

.tw-float-left {
  float: left;
}

.tw-float-none {
  float: none;
}

.tw-clear-left {
  clear: left;
}

.tw-clear-right {
  clear: right;
}

.tw-clear-both {
  clear: both;
}

.tw-clear-none {
  clear: none;
}

.tw-font-light {
  font-weight: 300;
}

.tw-font-normal {
  font-weight: 400;
}

.tw-font-semibold {
  font-weight: 600;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-h-0 {
  height: 0px;
}

.tw-h-1 {
  height: 0.25rem;
}

.tw-h-2 {
  height: 0.5rem;
}

.tw-h-5 {
  height: 1.25rem;
}

.tw-h-8 {
  height: 2rem;
}

.tw-h-9 {
  height: 2.25rem;
}

.tw-h-10 {
  height: 2.5rem;
}

.tw-h-12 {
  height: 3rem;
}

.tw-h-16 {
  height: 4rem;
}

.tw-h-20 {
  height: 5rem;
}

.tw-h-24 {
  height: 6rem;
}

.tw-h-auto {
  height: auto;
}

.tw-h-full {
  height: 100%;
}

.tw-h-screen {
  height: 100vh;
}

.tw-h-95-px {
  height: 95px;
}

.tw-h-70-px {
  height: 70px;
}

.tw-h-500-px {
  height: 500px;
}

.tw-h-600-px {
  height: 600px;
}

.tw-text-55 {
  font-size: 55rem;
}

.tw-text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.tw-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.tw-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tw-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tw-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.tw-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.tw-text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.tw-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.tw-text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.tw-leading-none {
  line-height: 1;
}

.tw-leading-snug {
  line-height: 1.375;
}

.tw-leading-normal {
  line-height: 1.5;
}

.tw-leading-relaxed {
  line-height: 1.625;
}

.tw-leading-9 {
  line-height: 2rem;
}

.tw-list-inside {
  list-style-position: inside;
}

.tw-list-outside {
  list-style-position: outside;
}

.tw-list-none {
  list-style-type: none;
}

.tw-m-1 {
  margin: 0.25rem;
}

.tw-m-2 {
  margin: 0.5rem;
}

.tw-m-3 {
  margin: 0.75rem;
}

.tw-m-4 {
  margin: 1rem;
}

.tw-m-8 {
  margin: 2rem;
}

.tw-m-14 {
  margin: 3.5rem;
}

.tw-m-16 {
  margin: 4rem;
}

.tw--m-16 {
  margin: -4rem;
}

.tw--m-24 {
  margin: -6rem;
}

.tw-my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tw-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tw-mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.tw-my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.tw-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.tw-mt-0 {
  margin-top: 0px;
}

.tw-mr-0 {
  margin-right: 0px;
}

.tw-mb-0 {
  margin-bottom: 0px;
}

.tw-mt-1 {
  margin-top: 0.25rem;
}

.tw-mr-1 {
  margin-right: 0.25rem;
}

.tw-mb-1 {
  margin-bottom: 0.25rem;
}

.tw-ml-1 {
  margin-left: 0.25rem;
}

.tw-mt-2 {
  margin-top: 0.5rem;
}

.tw-mr-2 {
  margin-right: 0.5rem;
}

.tw-mb-2 {
  margin-bottom: 0.5rem;
}

.tw-ml-2 {
  margin-left: 0.5rem;
}

.tw-mt-3 {
  margin-top: 0.75rem;
}

.tw-mr-3 {
  margin-right: 0.75rem;
}

.tw-mb-3 {
  margin-bottom: 0.75rem;
}

.tw-ml-3 {
  margin-left: 0.75rem;
}

.tw-mt-4 {
  margin-top: 1rem;
}

.tw-mr-4 {
  margin-right: 1rem !important;
}

.tw-mb-4 {
  margin-bottom: 1rem;
}

.tw-mt-5 {
  margin-top: 1.25rem;
}

.tw-mb-5 {
  margin-bottom: 1.25rem;
}

.tw-mt-6 {
  margin-top: 1.5rem;
}

.tw-mb-6 {
  margin-bottom: 1.5rem;
}

.tw-mt-8 {
  margin-top: 2rem;
}

.tw-mb-8 {
  margin-bottom: 2rem;
}

.tw-mt-10 {
  margin-top: 2.5rem;
}

.tw-mt-12 {
  margin-top: 3rem;
}

.tw-mb-12 {
  margin-bottom: 3rem;
}

.tw-mt-16 {
  margin-top: 4rem;
}

.tw-mt-20 {
  margin-top: 5rem;
}

.tw-mt-24 {
  margin-top: 6rem;
}

.tw-mb-24 {
  margin-bottom: 6rem;
}

.tw-mt-32 {
  margin-top: 8rem;
}

.tw-mt-48 {
  margin-top: 12rem;
}

.tw-mr-auto {
  margin-right: auto;
}

.tw-ml-auto {
  margin-left: auto;
}

.tw--ml-4 {
  margin-left: -1rem;
}

.tw-ml-4 {
  margin-left: 1rem;
}
.tw-ml-8 {
  margin-left: 2rem;
}

.tw-mt-20 {
  margin-top: -5rem;
}

.tw-ml-20 {
  margin-left: -5rem;
}

.tw-mt-24 {
  margin-top: -6rem;
}

.tw-mt-32 {
  margin-top: -8rem;
}

.tw-mt-48 {
  margin-top: -12rem;
}

.tw-mt-64 {
  margin-top: -16rem;
}

.tw-last\:mr-0:last-child {
  margin-right: 0px;
}

.tw-hover\:-mt-4:hover {
  margin-top: -1rem;
}

.tw-max-h-860-px {
  max-height: 860px;
}

.tw-max-w-md {
  max-width: 28rem;
}

.tw-max-w-xl {
  max-width: 36rem;
}

.tw-max-w-4xl {
  max-width: 56rem;
}

.tw-max-w-full {
  max-width: 100%;
}

.tw-max-w-100-px {
  max-width: 100px;
}

.tw-max-w-120-px {
  max-width: 120px;
}

.tw-max-w-150-px {
  max-width: 150px;
}

.tw-max-w-180-px {
  max-width: 180px;
}

.tw-max-w-200-px {
  max-width: 200px;
}

.tw-max-w-210-px {
  max-width: 210px;
}

.tw-max-w-580-px {
  max-width: 580px;
}

.tw-min-h-screen {
  min-height: 100vh;
}

.tw-min-h-screen-75 {
  min-height: 75vh;
}

.tw-min-w-0 {
  min-width: 0px;
}

.tw-min-w-48 {
  min-width: 12rem;
}

.tw-min-w-140-px {
  min-width: 140px;
}

.tw-object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.tw-object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.tw-object-fill {
  -o-object-fit: fill;
  object-fit: fill;
}

.tw-object-none {
  -o-object-fit: none;
  object-fit: none;
}

.tw-object-scale-down {
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.tw-opacity-50 {
  opacity: 0.5;
}

.tw-opacity-75 {
  opacity: 0.75;
}

.tw-opacity-80 {
  opacity: 0.8;
}

.tw-tw-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.tw-focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.tw-overflow-auto {
  overflow: auto;
}

.tw-overflow-hidden {
  overflow: hidden;
}

.tw-overflow-visible {
  overflow: visible;
}

.tw-overflow-scroll {
  overflow: scroll;
}

.tw-overflow-x-auto {
  overflow-x: auto;
}

.tw-overflow-y-auto {
  overflow-y: auto;
}

.tw-overflow-x-hidden {
  overflow-x: hidden;
}

.tw-overflow-y-hidden {
  overflow-y: hidden;
}

.tw-overflow-x-visible {
  overflow-x: visible;
}

.tw-overflow-y-visible {
  overflow-y: visible;
}

.tw-overflow-x-scroll {
  overflow-x: scroll;
}

.tw-overflow-y-scroll {
  overflow-y: scroll;
}

.tw-overscroll-auto {
  overscroll-behavior: auto;
}

.tw-overscroll-contain {
  overscroll-behavior: contain;
}

.tw-overscroll-none {
  overscroll-behavior: none;
}

.tw-overscroll-y-auto {
  overscroll-behavior-y: auto;
}

.tw-overscroll-y-contain {
  overscroll-behavior-y: contain;
}

.tw-overscroll-y-none {
  overscroll-behavior-y: none;
}

.tw-overscroll-x-auto {
  overscroll-behavior-x: auto;
}

.tw-overscroll-x-contain {
  overscroll-behavior-x: contain;
}

.tw-overscroll-x-none {
  overscroll-behavior-x: none;
}

.tw-p-1 {
  padding: 0.25rem;
}

.tw-p-2 {
  padding: 0.5rem;
}

.tw-p-3 {
  padding: 0.75rem;
}

.tw-p-4 {
  padding: 1rem;
}

.tw-p-5 {
  padding: 1.25rem;
}

.tw-p-8 {
  padding: 2rem;
}

.tw-p-14 {
  padding: 3.5rem;
}

.tw-p-16 {
  padding: 4rem;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.tw-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.tw-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.tw-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.tw-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.tw-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.tw-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.tw-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.tw-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tw-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tw-py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.tw-py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.tw-px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.tw-py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.tw-py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.tw-py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.tw-py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.tw-px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.tw-pt-0 {
  padding-top: 0px;
}

.tw-pb-0 {
  padding-bottom: 0px;
}

.tw-pt-1 {
  padding-top: 0.25rem;
}

.tw-pt-2 {
  padding-top: 0.5rem;
}

.tw-pt-4 {
  padding-top: 1rem;
}

.ttw-w-pb-2 {
  padding-bottom: 0.5rem;
}

.tw-pl-3 {
  padding-left: 0.75rem;
}

.tw-pr-4 {
  padding-right: 1rem;
}

.tw-pb-4 {
  padding-bottom: 1rem;
}

.tw-pl-4 {
  padding-left: 1rem;
}

.tw-pt-6 {
  padding-top: 1.5rem;
}

.tw-pb-6 {
  padding-bottom: 1.5rem;
}

.tw-pt-8 {
  padding-top: 2rem;
}

.tw-pl-10 {
  padding-left: 2.5rem;
}

.tw-pt-12 {
  padding-top: 3rem;
}

.tw-pr-12 {
  padding-right: 3rem;
}

.tw-pt-16 {
  padding-top: 4rem;
}

.tw-pb-16 {
  padding-bottom: 4rem;
}

.tw-pt-20 {
  padding-top: 5rem;
}

.tw-pb-20 {
  padding-bottom: 5rem;
}

.tw-pt-24 {
  padding-top: 6rem;
}

.tw-pt-32 {
  padding-top: 8rem;
}

.tw-pb-32 {
  padding-bottom: 8rem;
}

.tw-pb-40 {
  padding-bottom: 10rem;
}

.tw-pt-48 {
  padding-top: 12rem;
}

.tw-pb-48 {
  padding-bottom: 12rem;
}

.tw-pb-64 {
  padding-bottom: 16rem;
}

.tw-placeholder-blueGray-300::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-placeholder-opacity));
}

.tw-placeholder-blueGray-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-placeholder-opacity));
}

.tw-pointer-eventtw-s-none {
  pointer-events: none;
}

.tw-pointer-eventtw-s-auto {
  pointer-events: auto;
}

.tw-static {
  position: static;
}

.tw-fixed {
  position: fixed;
}

.tw-absolute {
  position: absolute;
}

.tw-relative {
  position: relative;
}

.tw-sticky {
  position: sticky;
}

.tw-top-0 {
  top: 0px;
}

.tw-right-0 {
  right: 0px;
}

.tw-bottom-0 {
  bottom: 0px;
}

.tw-left-0 {
  left: 0px;
}

.tw-top-auto {
  top: auto;
}

.tw-bottom-auto {
  bottom: auto;
}

.tw-left-auto {
  left: auto;
}

.tw-top-full {
  top: 100%;
}

.tw--right-100 {
  right: -100%;
}

.tw--top-225-px {
  top: -225px;
}

.tw-top-160-px {
  top: -160px;
}

.tw-top-150-px {
  top: -150px;
}

tw.-top-94-px {
  top: -94px;
}

.tw-left-50-px {
  left: -50px;
}

.tw--top-29-px {
  top: -29px;
}

.tw-left-20-px {
  left: -20px;
}

.tw-top-25-px {
  top: 25px;
}

.tw-left-40-px {
  left: 40px;
}

.tw-top-95-px {
  top: 95px;
}

.tw-left-145-px {
  left: 145px;
}

.tw-left-195-px {
  left: 195px;
}

.tw-top-210-px {
  top: 210px;
}

.tw-left-260-px {
  left: 260px;
}

.tw-resize-none {
  resize: none;
}

.tw-resize-y {
  resize: vertical;
}

.tw-resize-x {
  resize: horizontal;
}

.tw-resize {
  resize: bothtw-;
}

* {
  --tw-shadow: 0 0 #0000;
}

.tw-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

* {
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.tw-ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.tw-ring-inset {
  --tw-ring-inset: inset;
}

.tw-focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.tw-focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.tw-focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.tw-focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.tw-focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.tw-focus\:ring-black:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.tw-focus\:ring-indigo-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}

.tw-focus\:ring-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 113, 122, var(--tw-ring-opacity));
}

.tw-focus\:ring-opacity-50:focus {
  --tw-ring-opacity: 0.5;
}

.tw-fill-current {
  fill: currentColor;
}

.tw-table-auto {
  table-layout: auto;
}

.tw-table-fixed {
  table-layout: fixed;
}

.tw-text-left {
  text-align: left;
}

.tw-text-center {
  text-align: center;
}

.tw-text-right {
  text-align: right;
}

.tw-text-justify {
  text-align: justify;
}

.tw-text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.tw-text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.tw-text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.tw-text-lightBlue-400 {
  --tw-text-opacity: 1;
  color: rgba(56, 189, 248, var(--tw-text-opacity));
}

.tw-text-lightBlue-600 {
  --tw-text-opacity: 1;
  color: rgba(2, 132, 199, var(--tw-text-opacity));
}

.tw-text-teal-500 {
  --tw-text-opacity: 1;
  color: rgba(20, 184, 166, var(--tw-text-opacity));
}

.tw-text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.tw-text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(249, 115, 22, var(--tw-text-opacity));
}

.tw-text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}

.tw-text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.tw-text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.tw-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(113, 113, 122, var(--tw-text-opacity));
}

.tw-text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(82, 82, 91, var(--tw-text-opacity));
}

.tw-text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(63, 63, 70, var(--tw-text-opacity));
}

.tw-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(24, 24, 27, var(--tw-text-opacity));
}

.tw-text-blueGray-100 {
  --tw-text-opacity: 1;
  color: rgba(241, 245, 249, var(--tw-text-opacity));
}

.tw-text-blueGray-200 {
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity));
}

.tw-text-blueGray-300 {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-text-opacity));
}

.tw-text-blueGray-400 {
  --tw-text-opacity: 1;
  color: rgba(148, 163, 184, var(--tw-text-opacity));
}

.tw-text-blueGray-500 {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}

.tw-text-blueGray-600 {
  --tw-text-opacity: 1;
  color: rgba(71, 85, 105, var(--tw-text-opacity));
}

.tw-text-blueGray-700 {
  --tw-text-opacity: 1;
  color: rgba(51, 65, 85, var(--tw-text-opacity));
}

.tw-text-blueGray-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 41, 59, var(--tw-text-opacity));
}

.tw-text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.tw-text-blue-dark {
  color: #2779bd;
}

.tw-bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.tw-hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.tw-hover\:text-blueGray-300:hover {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-text-opacity));
}

.tw-hover\:text-blueGray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}

.tw-hover\:text-blueGray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 65, 85, var(--tw-text-opacity));
}

.tw-hover\:text-blueGray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 41, 59, var(--tw-text-opacity));
}

.tw-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tw-overflow-ellipsis {
  text-overflow: ellipsis;
}

.tw-overflow-clip {
  text-overflow: clip;
}

.tw-italic {
  font-style: italic;
}

.tw-not-italic {
  font-style: normal;
}

.tw-uppercase {
  text-transform: uppercase;
}

.tw-lowercase {
  text-transform: lowercase;
}

.tw-capitalize {
  text-transform: capitalize;
}

.tw-normal-case {
  text-transform: none;
}

.tw-underline {
  text-decoration: underline;
}

.tw-line-through {
  text-decoration: line-through;
}

.tw-no-underline {
  text-decoration: none;
}

.tw-antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tw-subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.tw-ordinal,
.tw-slashed-zero,
.tw-lining-nums,
.tw-oldstyle-nums,
.tw-proportional-nums,
.tw-tabular-nums,
.tw-diagonal-fractions,
.tw-stacked-fractions {
  --tw-ordinal: var(--tw-empty, /*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty, /*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty, /*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty, /*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty, /*!*/ /*!*/);
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
    var(--tw-numeric-figure) var(--tw-numeric-spacing)
    var(--tw-numeric-fraction);
}

.tw-normal-nums {
  font-variant-numeric: normal;
}

.tw-ordinal {
  --tw-ordinal: ordinal;
}

.tw-slashed-zero {
  --tw-slashed-zero: slashed-zero;
}

.tw-lining-nums {
  --tw-numeric-figure: lining-nums;
}

.tw-oldstyle-nums {
  --tw-numeric-figure: oldstyle-nums;
}

.tw-proportional-nums {
  --tw-numeric-spacing: proportional-nums;
}

.tw-tabular-nums {
  --tw-numeric-spacing: tabular-nums;
}

.tw-diagonal-fractions {
  --tw-numeric-fraction: diagonal-fractions;
}

.tw-stacked-fractions {
  --tw-numeric-fraction: stacked-fractions;
}

.tw-tracking-wide {
  letter-spacing: 0.025em;
}

.tw-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.tw-select-text {
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
}

.tw-select-all {
  -webkit-user-select: all;
  -moz-user-select: all;
  user-select: all;
}

.tw-select-auto {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  user-select: auto;
}

.tw-align-baseline {
  vertical-align: baseline;
}

.tw-align-top {
  vertical-align: top;
}

.tw-align-middle {
  vertical-align: middle;
}

.tw-align-bottom {
  vertical-align: bottom;
}

.tw-align-text-top {
  vertical-align: text-top;
}

.tw-align-text-bottom {
  vertical-align: text-bottom;
}

.tw-visible {
  visibility: visible;
}

.tw-invisible {
  visibility: hidden;
}

.tw-whitespace-normal {
  white-space: normal;
}

.tw-whitespace-nowrap {
  white-space: nowrap;
}

.tw-whitespace-pre {
  white-space: pre;
}

.tw-whitespace-pre-line {
  white-space: pre-line;
}

.tw-whitespace-pre-wrap {
  white-space: pre-wrap;
}

.tw-break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.tw-break-words {
  overflow-wrap: break-word;
}

.tw-break-all {
  word-break: break-all;
}

.tw-w-5 {
  width: 1.25rem;
}

.tw-w-8 {
  width: 2rem;
}

.tw-w-10 {
  width: 2.5rem;
}

.tw-w-12 {
  width: 3rem;
}

.tw-w-16 {
  width: 4rem;
}

.tw-w-auto {
  width: auto;
}

.tw-w-1\/2 {
  width: 50%;
}

.tw-w-6\/12 {
  width: 50%;
}

.tw-w-10\/12 {
  width: 83.333333%;
}

.tw-w-full {
  width: 100%;
}

.tw-z-2 {
  z-index: 2;
}

.tw-z-3 {
  z-index: 3;
}

.tw-z-10 {
  z-index: 10;
}

.tw-z-40 {
  z-index: 40;
}

.tw-z-50 {
  z-index: 50;
}

.tw-gap-6 {
  gap: 1.5rem;
}

.tw-grid-flow-row {
  grid-auto-flow: row;
}

.tw-grid-flow-col {
  grid-auto-flow: column;
}

.tw-grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.tw-grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.tw-transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-transform-gpu {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-transform-none {
  transform: none;
}

.tw-rotate-90 {
  --tw-rotate: 90deg;
}

.tw-rotate-180 {
  --tw-rotate: 180deg;
}

.tw-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.tw-transition {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.tw-ease-linear {
  transition-timing-function: linear;
}

.tw-ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.tw-ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.tw-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.tw-duration-150 {
  transition-duration: 150ms;
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

@-webkit-keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@media (min-width: 640px) {
  .tw-sm\:block {
    display: block;
  }

  .tw-sm\:hidden {
    display: none;
  }

  .tw-sm\:mt-0 {
    margin-top: 0px;
  }

  .tw-sm\:ml-1 {
    margin-left: 0.25rem;
  }

  .tw-sm\:mr-2 {
    margin-right: 0.5rem;
  }

  .tw-sm\:pt-0 {
    padding-top: 0px;
  }

  .tw-sm\:w-6\/12 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .tw-md\:block {
    display: block;
  }

  .tw-md\:flex {
    display: flex;
  }

  .tw-md\:hidden {
    display: none;
  }

  .tw-md\:flex-row {
    flex-direction: row;
  }

  .tw-md\:flex-col {
    flex-direction: column;
  }

  .tw-md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .tw-md\:items-stretch {
    align-items: stretch;
  }

  .tw-md\:justify-start {
    justify-content: flex-start;
  }

  .tw-md\:justify-end {
    justify-content: flex-end;
  }

  .tw-md\:justify-between {
    justify-content: space-between;
  }

  .tw-md\:mt-0 {
    margin-top: 0px;
  }

  .tw-md\:mt-4 {
    margin-top: 1rem;
  }

  .tw-md\:mb-4 {
    margin-bottom: 1rem;
  }

  .tw-md\:mt-40 {
    margin-top: 10rem;
  }

  .tw-md\:mt-64 {
    margin-top: 16rem;
  }

  .tw-md\:ml-64 {
    margin-left: 16rem;
  }

  .tw-md\:max-w-4xl {
    max-width: 56rem;
  }

  .tw-md\:min-h-full {
    min-height: 100%;
  }

  .tw-md\:min-w-full {
    min-width: 100%;
  }

  .tw-md\:opacity-100 {
    opacity: 1;
  }

  .tw-md\:overflow-hidden {
    overflow: hidden;
  }

  .tw-md\:overflow-y-auto {
    overflow-y: auto;
  }

  .tw-md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .tw-md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .tw-md\:pt-0 {
    padding-top: 0px;
  }

  .tw-md\:pb-2 {
    padding-bottom: 0.5rem;
  }

  .tw-md\:pr-12 {
    padding-right: 3rem;
  }

  .tw-md\:pt-32 {
    padding-top: 8rem;
  }

  .tw-md\:fixed {
    position: fixed;
  }

  .tw-md\:relative {
    position: relative;
  }

  .tw-md\:top-0 {
    top: 0px;
  }

  .tw-md\:bottom-0 {
    bottom: 0px;
  }

  .tw-md\:left-0 {
    left: 0px;
  }

  .tw-md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .tw-md\:text-left {
    text-align: left;
  }

  .tw-md\:w-64 {
    width: 16rem;
  }

  .tw-md\:w-4\/12 {
    width: 33.333333%;
  }

  .tw-md\:w-5\/12 {
    width: 41.666667%;
  }

  .tw-md\:w-6\/12 {
    width: 50%;
  }

  .tw-md\:w-8\/12 {
    width: 66.666667%;
  }

  .tw-md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .tw-lg\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .tw-lg\:block {
    display: block;
  }

  .tw-lg\:inline-block {
    display: inline-block;
  }

  .tw-lg\:flex {
    display: flex;
  }

  .tw-lg\:hidden {
    display: none;
  }

  .tw-lg\:flex-row {
    flex-direction: row;
  }

  .tw-lg\:self-center {
    align-self: center;
  }

  .tw-lg\:justify-start {
    justify-content: flex-start;
  }

  .tw-lg\:order-1 {
    order: 1;
  }

  .tw-lg\:order-2 {
    order: 2;
  }

  .tw-lg\:order-3 {
    order: 3;
  }

  .tw-lg\:mb-0 {
    margin-bottom: 0px;
  }

  .tw-lg\:mr-1 {
    margin-right: 0.25rem;
  }

  .tw-lg\:mr-4 {
    margin-right: 1rem;
  }

  .tw-lg\:mt-16 {
    margin-top: 4rem;
  }

  .tw-lg\:ml-auto {
    margin-left: auto;
  }

  .tw-lg\:-ml-16 {
    margin-left: -4rem;
  }

  .tw-lg\:-mt-64 {
    margin-top: -16rem;
  }

  .tw-lg\:p-10 {
    padding: 2.5rem;
  }

  .tw-lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .tw-lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .tw-lg\:pt-0 {
    padding-top: 0px;
  }

  .tw-lg\:pt-4 {
    padding-top: 1rem;
  }

  .tw-lg\:pt-12 {
    padding-top: 3rem;
  }

  .tw-lg\:pt-24 {
    padding-top: 6rem;
  }

  .tw-lg\:pb-64 {
    padding-bottom: 16rem;
  }

  .tw-lg\:static {
    position: static;
  }

  .tw-lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .tw-lg\:text-left {
    text-align: left;
  }

  .tw-lg\:text-right {
    text-align: right;
  }

  .tw-lg\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .tw-lg\:text-blueGray-200 {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .tw-lg\:hover\:text-blueGray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .tw-lg\:w-auto {
    width: auto;
  }

  .tw-lg\:w-3\/12 {
    width: 25%;
  }

  .tw-lg\:w-4\/12 {
    width: 33.333333%;
  }

  .tw-lg\:w-6\/12 {
    width: 50%;
  }

  .tw-lg\:w-8\/12 {
    width: 66.666667%;
  }

  .tw-lg\:w-9\/12 {
    width: 75%;
  }
}

@media (min-width: 1280px) {
  .tw-xl\:mb-0 {
    margin-bottom: 0px;
  }

  .tw-xl\:w-3\/12 {
    width: 25%;
  }

  .tw-xl\:w-4\/12 {
    width: 33.333333%;
  }

  .tw-xl\:w-6\/12 {
    width: 50%;
  }

  .tw-xl\:w-8\/12 {
    width: 66.666667%;
  }
}

@media (min-width: 1536px) {
}

.tw-bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.tw-bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.tw-bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.tw-w-32 {
  width: 8rem;
}

.tw-w-36 {
  width: 9rem;
}

.tw-h-32 {
  height: 8rem;
}

.tw-w-28 {
  width: 7rem;
}

.tw-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.tw-mr-8 {
  margin-right: 2rem;
}

.tw-pb-8 {
  padding-bottom: 2rem;
}

.tw-bg-green-200 {
  background-color: #c6f6d5;
}

.tw-text-green-800 {
  color: #276749;
}

.tw-text-amber-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.tw-bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.tw-divide-gray-700 {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}
