/* You can add global styles to this file, and also import other style files */

@import "~ngx-toastr/toastr.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

.error {
  color: #f44336;
  font-size: 11px;
  margin-top: 1%;
  font-weight: 500;
}

.row {
  display: flex;
}

.radioBtn {
  padding: 8px;
  width: 50%;
  display: flex;
  align-items: center;
}

.radioBtn input[type="radio"] {
  margin-right: 8%;
}

.verify_text {
  text-align: center;
  font-size: 20px !important;
  padding: 30px;
}

.custome-toast {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  color: #ffffff;
}

.custome-toast:hover {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  opacity: 1;
  cursor: pointer;
}

a {
  color: #0078d7;
}

.custome-toast .toast-error {
  background-image: none !important;
}

.clr-blue {
  color: #3c97e8;
}

.bg-clr-blue {
  background-color: #3c97e8;
  color: white;
}

.clr-green {
  color: #5f951a;
}

.bg-clr-green {
  background-color: #5f951a;
  color: white;
}

.bg-clr-red {
  background-color: #8b0000;
  color: white;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden;
}

.mat-drawer-backdrop.mat-drawer-shown {
  visibility: hidden !important;
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 2%),
    0px 16px 24px 2px rgb(0 0 0 / 2%), 0px 6px 30px 5px rgb(0 0 0 / 2%);
}

.content {
  display: inline-block;
  vertical-align: top;
  width: 86%;
  margin-top: 4.6rem;
  justify-content: space-between;
  margin-left: 16%;
}

.patientcontent {
  display: inline-block;
  vertical-align: top;
  margin-top: 4.6rem;
  justify-content: space-between;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
}

.video-content {
  width: 100%;
  /* display: inline-block;
  vertical-align: top; */
  /* width: calc(100% - 14rem); */
  /* margin-top: 3.6rem; */
  /* justify-content: space-between; */
  /* margin-left: -2%; */
}

.main-content {
  padding: 0.5rem 1rem;
  /* height: calc(100vh - 9.3rem); */
  overflow: hidden;
  /* background-color: #f0f2f5; */
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #3c97e8;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3c97e8;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #3c97e8 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #3c97e8;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #acd1f2;
}

.mat-paginator-outer-container {
  font-size: 16px;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #3c97e8;
}

.w-97 {
  width: 97%;
}

.w-94 {
  width: 94%;
}

.w-80per {
  width: 80%;
}

.w-85per {
  width: 85%;
}

.w-60per {
  width: 60%;
}

.w-10per {
  width: 10%;
}

.w-20per {
  width: 20%;
}

.w-40per {
  width: 40%;
}

.w-43per {
  width: 43%;
}

.btn-div {
  position: fixed;
  bottom: 5.5rem;
  right: 4rem;
}

.btn-div button {
  font-size: 16px;
}

.clr-gray {
  color: #666666;
}

a:hover {
  color: inherit;
}

.w-75per {
  width: 75%;
}

.w-8per {
  width: 8%;
}

.w-16per {
  width: 16%;
}

.bg-clr-gray {
  background-color: lightgray;
  color: black;
}

.mat-dialog-container {
  padding: 0px !important;
}

.mat-dialog-content {
  display: block;
  padding: 24px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.header {
  font-size: 14px;
  font-weight: 600;
  color: #6c6c6c;
}

.clr-gray-title {
  color: #6c6c6c;
}

.header-blue {
  font-size: 14px;
  font-weight: 600;
  color: #3c97e8;
}

.font-19 {
  font-size: 19px;
}

.font-17 {
  font-size: 17px;
}

.font-35 {
  font-size: 35px;
}

.font-12 {
  font-size: 12px;
}

.font-15 {
  font-size: 15px;
}

.reset-search {
  background-color: #ec971f;
  color: white;
  margin-left: 1% !important;
}

.w-11per {
  width: 11%;
}

a {
  text-decoration: none !important;
}

mat-timepicker-dialog {
  padding: 24px !important;
}

.circle {
  padding: 24px !important;
}

.mat-dialog-content {
  padding: 0 24px !important;
}

.w-94 {
  width: 94%;
}

.w-18 {
  width: 18%;
}

.w-15 {
  width: 15%;
}

.w-25 {
  width: 25%;
}

.w-80 {
  width: 80%;
}

.w-12per {
  width: 12%;
}

.w-13per {
  width: 13%;
}

.w-50 {
  width: 50%;
}

.w-47 {
  width: 46.5%;
}

.w-49 {
  width: 49%;
}

.w-58 {
  width: 58%;
}

.w-35 {
  width: 35%;
}

.w-33 {
  width: 33%;
}

.w-79 {
  width: 79%;
}

.w-100 {
  width: 100% !important;
}

.w-70 {
  width: 70%;
}

.w-30per {
  width: 30%;
}

.pt2 {
  padding-top: 0.2rem;
}

.pb2 {
  padding-bottom: 0.2rem;
}

.label-success {
  background-color: #5cb85c;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label-info {
  background-color: #5bc0de;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  height: 20px;
}

.label-danger {
  background-color: #d9534f;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.heading {
  color: #0078d7;
}

.clr-white {
  color: white !important;
}

button:focus {
  outline: none;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
  height: 12rem;
}

.main-div {
  padding: 2% 6% 4% 6%;
}

.scrolldialog {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 70vh;
  height: 70vh;
  min-height: 20vh;
}

.mat-paginator {
  background: #fafafa;
}

.w-22per {
  width: 22%;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: lightgray !important;
  outline: 0;
  box-shadow: none !important;
}

.suffixramt {
  display: block;
  position: absolute;
  top: 5px;
  font-weight: 600 !important;
}

.amtinput {
  margin-left: 2.5rem !important;
}

.amtinputMin {
  margin-left: 4rem !important;
}

.clr-red {
  color: #a94442;
}

.clr-darkred {
  color: red;
}

.cliniccontent {
  display: inline-block;
  vertical-align: top;
  width: 80%;
  /* height: calc(100vh - 4.6rem); */
  margin-top: 4.6rem;
  justify-content: space-between;
  margin-left: 20%;
}

.cliniccontent2 {
  display: inline-block;
  vertical-align: top;
  width: 80%;
  /* height: calc(100vh - 4.6rem); */
  margin-top: 4.6rem;
  justify-content: space-between;
  margin-left: 20%;
}

.cscroll {
  overflow: auto;
  height: 100vh;
}

.bg-i {
  height: 120vh !important;
}

.str-chat__message-text {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  position: relative;
  margin: 1%;
}

.str-chat__li--single .str-chat__message-text-inner {
  border-radius: 16px 16px 16px 2px;
}

.str-chat__message-simple-text-inner {
  -webkit-flex: initial;
  flex: initial;
  text-align: left;
  max-width: 460px;
}

.str-chat__message-simple-text-inner,
.str-chat__message-text-inner {
  position: relative;
  -webkit-flex: 1 1;
  flex: 1 1;
  display: block;
  min-height: 32px;
  padding: 5px 10px;
  font-size: 15px;
  color: #000;
  border-radius: 16px 16px 16px 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-left: 0;
}

.str-chat__message-simple-text-inner1,
.str-chat__message-text-inner1 {
  position: relative;
  flex: 1 1;
  display: block;
  min-height: 32px;
  padding: 5px 10px;
  font-size: 15px;
  color: #000;
  border-radius: 16px 16px 0 16px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-left: 0;
}

.str-chat__message-text1 {
  display: grid;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  position: relative;
  margin: 1%;
}

.hide {
  display: none;
}

/* Videocall starts */
.video_bar {
  z-index: 1099;
  position: absolute;
  bottom: 0%;
}

.video2 {
  left: 0;
}
/* Videocall ends */

/* -----  Opentok starts ---- */
.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}
.bg-gray-400 {
  background-color: rgb(133, 133, 133);
}
.bg-gray-100 {
  background-color: rgb(223, 223, 223);
}
.grid {
  display: grid;
}
.grid-auto-col {
  grid-auto-flow: column;
}
.gap {
  gap: 5px;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, 1fr);
}
.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}
.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}
.grid-cols-4 {
  grid-template-columns: repeat(2, 2fr);
  overflow-x: hidden;
  overflow-y: scroll;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
/* -----  Opentok ends ---- */
